<template>
  <div>
    <GmapMap :center="{ lat:position.lat, lng:position.lng}" :zoom="5" :style="'height:'+height"
             class="googleMaps">
      <GmapMarker :position="position"
                  :clickable="true"
                  :draggable="true"
                  @click="center=position"/>
    </GmapMap>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: Object,
    },
    height: {
      type: String,
    }
  }
}
</script>

<style scoped>
</style>