<template>
  <div>
    <v-dialog v-model="dialog"
              max-width="800"
              @click:outside="close"
              persistent
              scrollable>

      <v-card :disabled="v_loading || r_loading"
              :loading="v_loading || r_loading">

        <v-overlay absolute :value="v_loading || r_loading" opacity="0.5" color="white">
          <v-progress-circular indeterminate size="50" color="primary"></v-progress-circular>
        </v-overlay>

        <v-card-title>
          {{ task ? task.task.name : '' }}
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>


        <v-card-text class="pa-8"
                     v-if="loading">
          <v-row>
            <v-col>
              <v-skeleton-loader type="image,article"/>
            </v-col>
            <v-col>
              <v-skeleton-loader type="image,article"/>
            </v-col>
          </v-row>
        </v-card-text>


        <v-card-text class="pa-8"
                     v-if="!loading">

          <div v-for="(res,index) in responses" :key="index">

            <v-row justify="center" align="center">
              <v-col cols="12">
                <h3 class="text-primary">
                  Question
                </h3>
                <p class="mt-2">
                  {{ res.question.description }}
                </p>
              </v-col>
            </v-row>

            <div v-if="res.answer_type.key === 'image'">
              <v-row>
                <v-col cols="6">

                  <v-chip small
                          color="blue"
                          label
                          class="mb-2" dark>
                    <v-icon small left>mdi-text-box-search</v-icon>
                    Intitulé
                  </v-chip>

                  <v-img height="300"
                         v-if="res.question.conditions && res.question.conditions.length === 0"
                         @click="[image = [{src: FILE_URL + res.question.content}],visible = true]"
                         class="rounded-lg zoom-pointer grey lighten-3"
                         :src="FILE_URL+res.question.content">
                  </v-img>

                  <div v-if="res.question.conditions && res.question.conditions.length >0">
                    <v-chip small color="blue" label class="mb-1" dark>Intitulé</v-chip>
                    <v-simple-table class="table-border">
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th>Image</th>
                          <th>Produit</th>
                          <th class="text-center">Box</th>
                          <th class="text-center">Quantité</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(condition,i) in res.question.conditions" :key="i">
                          <td>
                            <v-img v-if="condition.photo" :src="FILE_URL + condition.photo" width="40"/>
                            <v-img v-else :src="require('@/assets/no-image.png')" width="40"/>
                          </td>
                          <td>{{ condition.product.name }}</td>
                          <td class="text-center">
                            <v-icon v-if="condition.is_box" color="primary">mdi-check-circle-outline</v-icon>
                            <v-icon v-else>mdi-circle-outline</v-icon>
                          </td>
                          <td class="text-center">{{ condition.quantity }}</td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-col>

                <v-col cols="6">

                  <v-chip small
                          color="blue"
                          label
                          class="mb-2" dark>
                    <v-icon small left>mdi-text-box-check</v-icon>
                    Réponse
                  </v-chip>

                  <v-img height="300"
                         class="rounded-lg zoom-pointer light-blue"
                         @click="[image = [{src: FILE_URL + res.response}],visible = true]"
                         :src="FILE_URL+res.response">
                  </v-img>
                </v-col>

              </v-row>
            </div>

            <div v-if="res.answer_type.key === 'radio'">
              <v-row justify="center" align="center">
                <v-col>
                  <v-list>
                    <v-list-item v-for="(option, i) in res.question.options" :key="i">
                      <v-list-item-avatar>
                        <v-icon color="primary" v-if="parseInt(res.response) === parseInt(option.id)">
                          mdi-radiobox-marked
                        </v-icon>
                        <v-icon v-else>mdi-radiobox-blank</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-text="option.option"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </div>

            <div v-if="res.answer_type.key === 'checkbox'">
              <v-row>
                <v-col cols="6">
                  <v-list>
                    <v-list-item v-for="(option, i) in res.question.options" :key="i">
                      <v-list-item-icon>
                        <v-icon color="primary" v-if="(res.response).includes(option.id)">mdi-checkbox-outline
                        </v-icon>
                        <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="option.option"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </div>


            <hr v-if="index+1 !== responses.length"
                class="mt-5 mb-5"
                :style="{borderTop: '2px dashed rgba(0,0,0,.3)' , borderBottom: 0}">

          </div>
        </v-card-text>


        <v-card-actions class="shadow"
                        v-if="!loading && task && task.state.origin ==='pending' && task.task_model_id !== null">
          <v-spacer/>
          <v-btn color="red" dark depressed class="rounded-lg"
                 :loading="r_loading"
                 @click="refuseDialog = true">
            <v-icon left>mdi-close-circle-outline</v-icon>
            Refuser
          </v-btn>

          <v-btn color="success" depressed class="ml-2 rounded-lg"
                 :loading="v_loading"
                 @click="validateTask(task.salepoint_id,task.task_id)">
            <v-icon left>mdi-check-circle-outline</v-icon>
            Valider
          </v-btn>

        </v-card-actions>

        <vue-easy-lightbox escDisabled
                           moveDisabled
                           :visible="visible"
                           :imgs="image"
                           :index="index"
                           @hide="visible = false"></vue-easy-lightbox>

      </v-card>
    </v-dialog>

    <!--   Refuse single Dialog-->
    <RefuseSingleDialog :dialog.sync="refuseDialog"
                        :task.sync="task"
                        @close-refuse-dialog="closeRefuseDialog"/>


  </div>
</template>

<script>

import RefuseSingleDialog from "../temp-validation/Dialogs/RefuseSingleDialog";
import {HTTP} from "@/http-common";

export default {
  components: {RefuseSingleDialog},
  props: ['dialog', 'task'],
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL,
      r_loading: false,
      v_loading: false,
      loading: false,
      responses: [],
      refuseDialog: false,
      //Show image
      image: '',
      visible: false,
      index: 0
    }
  },
  methods: {
    close() {
      this.$emit('update:dialog', false)
    },
    closeRefuseDialog() {
      this.$emit('update:dialog', false)
      this.$emit('get-tasks')
    },
    showResponses(salepoint_id, task_id) {
      this.responses = []
      this.loading = true
      this.$Progress.start()
      HTTP.get('tasks/responses', {
        params: {
          task_id: task_id,
          salepoint_id: salepoint_id,
        }
      }).then((res) => {
        this.responses = res.data.data
        this.$Progress.finish()
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$Progress.fail()
        console.log(err)
      })
    },

    validateTask(salepoint_id, task_id) {
      this.$Progress.start()
      this.v_loading = true
      HTTP.post('/tasks/temp/validate', {
        task_id: task_id,
        salepoint_id: salepoint_id,
      }).then(() => {
        this.v_loading = false
        this.$Progress.finish()
        this.$successMessage = 'Cette tâche VALIDER avec succès'
        this.$emit('get-tasks')
        this.close()
      }).catch(err => {
        this.$Progress.fail()
        this.v_loading = false
        console.log(err)
      })
    },
  }
}
</script>

<style scoped>

</style>