<template>
  <div>
    <v-dialog v-model="dialog" width="500" persistent scrollable>
      <v-card :loading="loading" :disabled="loading">
        <v-card-text class="pa-6 text-center">
          <v-icon color="primary" size="80">mdi-information-outline</v-icon>
          <h2 class="text--primary mt-3 mb-5">Confirmation de réfus</h2>
          <v-row justify="center" align="center" class="mb-3">
            <v-col cols="12">

              <v-select filled
                        hide-details="auto"
                        label="Veuillez séléctionner un raison *"
                        v-model="refuse.raison"
                        class="mb-3"
                        :items="raisons"
                        item-value="key"
                        item-text="name">
              </v-select>

              <v-autocomplete
                  class="mb-3"
                  chips
                  small-chips
                  deletable-chips
                  multiple
                  v-model="refuse.products"
                  filled
                  hide-details="auto"
                  label="Veuillez séléctionner les produits *"
                  :items="products"
                  item-value="id"
                  item-text="name">
              </v-autocomplete>

              <v-textarea rows="2"
                          label="Remarque"
                          filled hide-details
                          v-model="refuse.remark"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>

          <v-btn text class="mr-2" @click="close">
            <v-icon left>mdi-arrow-left</v-icon>
            Annuler
          </v-btn>

          <v-btn color="red" depressed
                 :loading="loading"
                 :class="!refuseBtnDisable() ? 'white--text':''"
                 :disabled="refuseBtnDisable()"
                 @click="refuseTask(task.salepoint_id,task.task_id)">
            <v-icon left>mdi-close</v-icon>
            Réfuser
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  props: ['dialog', 'selectedTasks', 'task'],
  data() {
    return {
      refuse: {
        raison: '',
        remark: '',
        products: [],
      },
      loading: false
    }
  },
  methods: {
    refuseTask(salepoint_id, task_id) {
      this.loading = true
      this.$Progress.start()
      HTTP.post('/tasks/temp/refuse', {
        task_id: task_id,
        salepoint_id: salepoint_id,
        raison: this.refuse.raison,
        remark: this.refuse.remark,
        products: this.refuse.products,
      }).then(() => {
        this.$emit('close-refuse-dialog')
        this.close()
        this.$successMessage = 'Cette tâche REFUSER avec succès'
        this.loading = false
        this.$Progress.finish()
      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        this.loading = false
        console.log(err)
      })
    },
    refuseBtnDisable() {
      if (!this.refuse.raison) {
        return true
      }
    },
    close() {
      this.$emit('update:dialog', false)
    }
  },
  computed: {
    raisons() {
      return this.$store.state.raisons
    },
    products() {
      return this.$store.state.products
    },
  }
}
</script>

<style scoped>

</style>