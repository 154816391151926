<template>
  <div>
    <v-toolbar class="rounded-lg transparent" flat>
      <v-tabs v-model="active_tab">

        <v-tab class="b-700"
               v-if="can('Validation temperaire')"
               @click="$router.push('/backoffice')">
          <v-icon left>mdi-clock-check-outline</v-icon>
          Validation temporaire
        </v-tab>

        <v-tab class="b-700"
               v-if="can('Validation finale')"
               @click="$router.push('/backoffice/final-validation')">
          <v-icon left>mdi-clipboard-check-outline</v-icon>
          Validation finale
        </v-tab>

        <v-tab class="b-700"
               v-if="can('Terminées')"
               @click="$router.push('/backoffice/completed')">
          <v-icon left>mdi-folder-text-outline</v-icon>
          Terminées
        </v-tab>

      </v-tabs>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  computed: {
    active_tab: {
      get() {
        if (this.$route.name === 'backoffice.tempValidation') {
          return 0;
        }
        if (this.$route.name === 'backoffice.finalValidation') {
          return 1;
        }
        if (this.$route.name === 'backoffice.finalValidation.tasks') {
          return 1;
        }
        if (this.$route.name === 'backoffice.completed') {
          return 2;
        }
        if (this.$route.name === 'backoffice.completed.tasks') {
          return 2;
        }
        return 0;
      },
      set(newName) {
        return newName
      }
    }
  }
}
</script>

<style scoped>

</style>