<template>
  <div>
    <v-dialog v-model="dialog" width="600" persistent scrollable>
      <v-card>
        <v-card-title>
          Information de POS
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-simple-table class="table-border mt-2">
            <template v-slot:default>
              <tbody>
              <tr>
                <td><span class="text--primary text-uppercase">Photo : </span></td>
                <td>
                  <v-avatar size="38">
                    <v-img v-if="salepoint.salepoint_account && salepoint.salepoint_account.photo"
                           :src="FILE_URL + salepoint.salepoint_account.photo"></v-img>
                    <v-img v-else :src="require('@/assets/avatar2.png')"></v-img>
                  </v-avatar>
                </td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_26">{{ keyword.key_26 }} : </span></td>
                <td>{{ salepoint.code }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_1">{{ keyword.key_1 }} : </span></td>
                <td>{{ salepoint.type }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_27">{{ keyword.key_27 }} :</span></td>
                <td>{{ salepoint.name }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase">{{ keyword.key_27 }}- APP :</span></td>
                <td>
                  <span v-if="salepoint.salepoint_account">
                        {{ salepoint.salepoint_account.fullname }}
                      </span>
                  <span v-else>-</span>
                </td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_2">{{ keyword.key_2 }} :</span></td>
                <td>{{ salepoint.region }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_3">{{ keyword.key_3 }} :</span></td>
                <td>{{ salepoint.city }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_4">{{ keyword.key_4 }} :</span></td>
                <td>{{ salepoint.municipality }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_40">{{ keyword.key_40 }}:</span></td>
                <td>{{ salepoint.address }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_28">{{ keyword.key_28 }} :</span></td>
                <td>
                  {{ salepoint.phone }}
                </td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase">{{ keyword.key_28 }} - APP :</span></td>
                <td>
                       <span v-if="salepoint.salepoint_account && salepoint.salepoint_account.salepoint_phone"
                             class="info--text">
                      {{ salepoint.salepoint_account.salepoint_phone }}
                      </span>
                  <span v-else>
                    -
                  </span>
                </td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_30">{{ keyword.key_30 }} :</span></td>
                <td>{{ salepoint.benchmark_institution }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_29">{{ keyword.key_29 }} :</span></td>
                <td>{{ salepoint.canal }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_5">{{ keyword.key_5 }}:</span></td>
                <td>{{ salepoint.role_b }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_32">{{ keyword.key_32 }}:</span></td>
                <td>{{ salepoint.coverage_b }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_33">{{ keyword.key_33 }} :</span></td>
                <td>{{ salepoint.last_call_b }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_34">{{ keyword.key_34 }} :</span></td>
                <td>{{ salepoint.number_call_b }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_6">{{ keyword.key_6 }} :</span></td>
                <td>{{ salepoint.role_c }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_35">{{ keyword.key_35 }} :</span></td>
                <td>{{ salepoint.coverage_c }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_36">{{ keyword.key_36 }} :</span></td>
                <td>{{ salepoint.last_call_c }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_37">{{ keyword.key_37 }} :</span></td>
                <td>{{ salepoint.number_call_c }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_38">{{ keyword.key_38 }} :</span></td>
                <td>{{ salepoint.tip }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_7">{{ keyword.key_7 }} :</span></td>
                <td>
                  <v-icon v-if="!salepoint.salepoint_platinum" color="error">mdi-close-circle</v-icon>
                  <v-icon v-else color="success">mdi-check-circle</v-icon>
                </td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_8">{{ keyword.key_8 }} :</span></td>
                <td>{{ salepoint.class }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_39">{{ keyword.key_39 }} :</span></td>
                <td>{{ salepoint.declare_by }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_31">{{ keyword.key_31 }} :</span></td>
                <td>{{ salepoint.landmark }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_10">{{ keyword.key_10 }} :</span></td>
                <td>{{ salepoint.sum_of_M_106 }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_11">{{ keyword.key_11 }} :</span></td>
                <td>{{ salepoint.sum_of_M_70 }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_12">{{ keyword.key_12 }} :</span></td>
                <td>{{ salepoint.sum_of_ULT }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_13">{{ keyword.key_13 }} :</span></td>
                <td>{{ salepoint.sum_of_HSP }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_14">{{ keyword.key_14 }} :</span></td>
                <td>{{ salepoint.sum_of_Con92_1 }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_15">{{ keyword.key_15 }} :</span></td>
                <td>{{ salepoint.sum_of_Con92_2 }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_16">{{ keyword.key_16 }} :</span></td>
                <td>{{ salepoint.sum_of_OV_GM_1 }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_17">{{ keyword.key_17 }} :</span></td>
                <td>{{ salepoint.sum_of_OV_GM_2 }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_18">{{ keyword.key_18 }} :</span></td>
                <td>{{ salepoint.sum_of_OV_PM }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_19">{{ keyword.key_19 }} :</span></td>
                <td>{{ salepoint.sum_of_Disps }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_20">{{ keyword.key_20 }} :</span></td>
                <td>{{ salepoint.sum_of_Waf_66 }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_21">{{ keyword.key_21 }} :</span></td>
                <td>{{ salepoint.sum_of_Waf_99 }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_22">{{ keyword.key_22 }} :</span></td>
                <td>{{ salepoint.sum_of_M_M }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_23">{{ keyword.key_23 }} :</span></td>
                <td>{{ salepoint.sum_of_M_C1 }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_24">{{ keyword.key_24 }} :</span></td>
                <td>{{ salepoint.sum_of_M_C2 }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase" id="key_25">{{ keyword.key_25 }} :</span></td>
                <td>{{ salepoint.sum_of_M_C3 }}</td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase">Total des points : </span></td>
                <td>
                  <v-chip small color="blue" dark v-if="salepoint.salepoint_account">
                    {{ salepoint.salepoint_account.points | toCurrency }}
                  </v-chip>
                  <v-chip small color="blue" disabled dark v-else>
                    0.00
                  </v-chip>
                </td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase">Compte Gifty : </span></td>
                <td>
                  <v-icon v-if="salepoint.salepoint_account && salepoint.salepoint_account.gifty_id" color="success">
                    mdi-check-circle
                  </v-icon>
                  <v-icon v-else color="error">mdi-close-circle</v-icon>
                </td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase">Compte TIP : </span></td>
                <td>
                  <v-icon v-if="!salepoint.salepoint_account" color="error">mdi-close-circle</v-icon>
                  <v-icon v-else color="success">mdi-check-circle</v-icon>
                </td>
              </tr>
              <tr>
                <td><span class="text--primary text-uppercase">Statut : </span></td>
                <td>
                  <v-chip dark small color="success" v-if="salepoint.is_active">Active</v-chip>
                  <v-chip dark small color="error" v-else>Inactive</v-chip>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <google-maps
              v-if="salepoint.latitude && salepoint.longitude"
              class="mt-2"
              :position="{lat:salepoint.latitude,lng:salepoint.longitude}"
              height="250px"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>


import GoogleMaps from "../../../components/Maps/googleMaps";

export default {
  components: {GoogleMaps},
  props: ['dialog', 'salepoint'],
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL
    }
  },
  methods: {
    close() {
      this.$emit('update:dialog', false)
    }
  },
  computed: {
    keyword() {
      return this.$store.state.keyword
    },
  }
}
</script>

<style scoped>

</style>